import {
  buildIssueStoreLoader,
  SpecialIssue,
  SPRINTS_CFG_ISSUE_ID,
} from './SpecialIssue'
import { getDayDate } from '~/utils/dates'
import { Dayjs } from 'dayjs'

type GLSprintCfgData = Record<number, GLDescriptionPayload>

type SprintCfgData = {
  teams: TeamsCfg
  sprintDates: Dayjs[]
}

@store()
export class SprintsCfg extends SpecialIssue<GLSprintCfgData> {
  /**
   * UPSERT!
   * updates already existing sprint (if sprintNumber is already present) or
   * creates a new one
   * @param sprintNumber
   * @param teams
   * @param sprintDates
   */
  saveSprintCfg(sprintNumber: number, teams: TeamsCfg, sprintDates: Dayjs[]) {
    return this._save((data) => {
      data[sprintNumber] = {
        sprintDates: sprintDates.map((d) => d.toISOString()),
        teams,
      }
      return data
    })
  }
  get cfgs(): Record<number, SprintCfgData> {
    return mapValues(
      ({ sprintDates, teams }) => ({
        sprintDates: sprintDates.map((d) => getDayDate(d)),
        teams,
      }),
      this.data,
    )
  }
}

export const getSprintsCfg = buildIssueStoreLoader(
  SPRINTS_CFG_ISSUE_ID,
  SprintsCfg,
)
