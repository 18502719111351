import { allDevs } from '~/services/cfg'
import { type Sprint } from '../Sprint'
import { sprintsHub } from '../SprintsHub'

const SPRINTS_NUMBER_TO_CONSIDER = 3

const getUserDaysOff = (teamsCfg: TeamsCfg, glId: number) => {
  for (const team of Object.values(teamsCfg)) {
    for (const collection of ['BErs', 'FErs'] as const) {
      const dev = team[collection].find((dev) => dev.glId === glId)
      if (dev) return dev.daysOff
    }
  }
}
const getUserPresenceFactor = (sprint: Sprint, glId: number) => {
  const daysOff = getUserDaysOff(sprint.teams, glId)
  return daysOff === undefined ? undefined : sprint.sprintDates.length - daysOff
}

const getLastSprintsResult = (
  dev: Dev,
  sprints: Sprint[],
  includeCurrentSprint: boolean,
) => {
  const res: {
    pf: number
    delivered: number
    sprintNumber: number
  }[] = []
  for (const sprint of sprints) {
    if (res.length === SPRINTS_NUMBER_TO_CONSIDER) break
    const result = includeCurrentSprint
      ? sprint.currentOrSnapshotResults
      : sprint.resultsSnapshot
    const devResult = result?.individual[dev.glId]
    const pf = getUserPresenceFactor(sprint, dev.glId)
    if (!devResult || !pf) continue
    res.push({
      pf,
      delivered: devResult.delivered,
      sprintNumber: sprint.number,
    })
  }
  return res
}

/**
 * returns actual dev's velocity.
 * It is computed as the average of the velocity of his last <SPRINTS_NUMBER_TO_CONSIDER> sprints.
 * If the dev has less than <SPRINTS_NUMBER_TO_CONSIDER> sprints, the average is computed on the available sprints.
 */
const getDevVelocity = (
  dev: Dev,
  sprints: Sprint[],
  includeCurrentSprint: boolean,
) => {
  const lastSprintsResults = getLastSprintsResult(
    dev,
    sprints,
    includeCurrentSprint,
  )
  if (!!lastSprintsResults.length) {
    console.log(
      `Computed perf values for ${dev.name}/${dev.glId} (sprints ${lastSprintsResults?.map((x) => x.sprintNumber)}):
`,
      lastSprintsResults
        ?.map(
          (x) =>
            `#${x.sprintNumber}: ${x.delivered}/${x.pf} = ${x.delivered / x.pf}`,
        )
        .join('\n'),
    )
    const totPF = lastSprintsResults.sum((r) => r.pf)
    if (totPF !== 0) {
      const totDelivered = lastSprintsResults.sum((r) => r.delivered)
      return totDelivered / totPF
    }
  }
}

// TODO: move and rename next methods in utils/object.

const sprints = computed(() => {
  if (!sprintsHub.closedSprints) return undefined
  if (sprintsHub.includeCurrentSprintInForecast) {
    return sprintsHub.currentSprint
      ? [...sprintsHub.closedSprints, sprintsHub.currentSprint].reverse()
      : undefined
  }
  return [...sprintsHub.closedSprints].reverse()
})

export const getDevVelocityRecord = () =>
  computedObj((glIdStr) => {
    const glId = Number(glIdStr)
    // computedDynObjNum((glId) => {
    const dev = allDevs.find((d) => d.glId === glId)
    if (dev && sprints.value) {
      return getDevVelocity(
        dev,
        sprints.value,
        sprintsHub.includeCurrentSprintInForecast,
      )
    }
  })
