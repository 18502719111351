import { SpecialIssue } from './SpecialIssue'

export class OrderManager extends SpecialIssue<number[]> {
  getOrderedItems<T extends WithIid>(items: T[]) {
    return items.orderByIid(this.data)
  }
  move<T extends WithIid>(items: T[], moveFrom: T, moveTo: T) {
    if (moveFrom === moveTo) return
    const order = items.map((seed) => seed.iid)
    const moveFromIndex = order.indexOf(moveFrom.iid)
    const moveToIndex = order.indexOf(moveTo.iid)
    order.move(moveFromIndex, moveToIndex)

    if (!order.equals(this.data)) return this._save(order)
  }
}
