import {
  buildIssueStoreLoader,
  CANDIDATE_SPRINT_ISSUES,
  SpecialIssue,
} from './SpecialIssue'

type SprintNumber = number
type IssueCandidate = {
  iid: number
  sprintNumber: SprintNumber
  nth: boolean
}
type Payload = IssueCandidate[]

/**
 * Issues can be assigned to just a sprint, but it is possible to candidate
 * a current sprint issue for a future one in planning to take into account
 * issues that are likely not to be released in time and should be postponed
 * in our capacity computation and planning.
 *
 * This module allows candidacy management
 */
@store()
export class SprintCandidates extends SpecialIssue<Payload> {
  get issues() {
    return super.data
  }
  /**
   * adds an issue to a sprint (removing it from current one) if targetSprintNumber is provided
   * or removes an issue from a sprint if targetSprintNumber is not provided.
   * If the issue is found in a sprint, the nth flag is preserved, otherwise it is set to false.
   * @param issueIid
   * @param targetSprintNumber
   * @returns
   */
  setAssignation(issueIid: number, targetSprintNumber?: SprintNumber) {
    if (!targetSprintNumber) {
      this.data.removeByIid(issueIid)
    } else {
      const issue = this.data.findByIid(issueIid)
      if (issue) {
        issue.sprintNumber = targetSprintNumber
      } else {
        this.data.push({
          iid: issueIid,
          sprintNumber: targetSprintNumber,
          nth: false,
        })
      }
    }
    return this._save(this.data)
  }

  async toggleNth(issueIid: number) {
    const issue = this.data.findByIid(issueIid)
    if (issue) {
      issue.nth = !issue.nth
      return this._save(this.data)
    }
  }
}

export const getSprintCandidates = buildIssueStoreLoader(
  CANDIDATE_SPRINT_ISSUES,
  SprintCandidates,
)
