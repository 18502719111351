import type { Issue } from '~/api/issues/models'
import { type Sprint } from '../Sprint'
import { sprintsHub } from '../SprintsHub'

const getLoadStatus = (percLoadOnForecast: number) =>
  percLoadOnForecast > 120
    ? 'over'
    : percLoadOnForecast > 100
      ? 'slightlyOver'
      : percLoadOnForecast > 80
        ? 'slightlyUnder'
        : 'under'

const buildLeaf = (
  sprint: Sprint,
  teamName: TeamName,
  domain: Domain | 'noDomain',
  mandatoryIssues: ComputedRef<Issue[]>,
  nthIssues: ComputedRef<Issue[]>,
) => {
  const devs = computed(
    () => sprint.teams[teamName][domain === 'be' ? 'BErs' : 'FErs'] ?? [],
  )
  const off = computed(() => devs.value.sum((dev) => dev.daysOff))
  const PF = computed(() => sprint.length * devs.value.length - off.value)

  // TODO: to be removed: use this SPRINT velocity avg
  const avgDevsVelocity = computed(() =>
    devs.value
      .map(({ glId }) => sprintsHub.devVelocity[glId])
      .filter((v) => v !== undefined)
      .avg((v) => v, 0),
  )

  /**
   * Forecast is computed as the sum of individual dev forecasts by domain only!
   * Sum of all domains aggregate gives the total forecast based on the sprint's team velocity,
   * so for no-domain aggregate, since it is meaningless, it's always 0 and doesn't affect the total forecast.
   */
  const forecast = computed(() =>
    domain === 'noDomain'
      ? 0
      : devs.value.sum(
          ({ daysOff, glId }) =>
            (sprintsHub.devVelocity[glId] ?? avgDevsVelocity.value) *
            (sprint.length - daysOff),
        ),
  )
  const velocity = computed(() =>
    PF.value === 0 ? 0 : forecast.value / PF.value,
  )
  const mandatoryLoad = computed(() =>
    mandatoryIssues.value.sum((i) => i.stats[domain].weight ?? 0),
  )
  const nthLoad = computed(() =>
    nthIssues.value.sum((i) => i.stats[domain].weight ?? 0),
  )
  const totLoad = computed(() => mandatoryLoad.value + nthLoad.value)
  const nthLoadPerc = computed(() => (nthLoad.value * 100) / totLoad.value)
  const percLoadOnForecast = computed(
    () => (mandatoryLoad.value * 100) / forecast.value,
  )

  const loadStatus = computed(() => getLoadStatus(percLoadOnForecast.value))

  return computedObj({
    off,
    PF,
    // avgDevsVelocity,
    velocity,
    forecast,
    mandatoryLoad,
    nthLoad,
    totLoad,
    nthLoadPerc,
    percLoadOnForecast,
    loadStatus,
  })
}

const buildAggregate = (leaves: ReturnType<typeof buildLeaf>[]) => {
  const off = computed(() => leaves.sum((l) => l.off))
  const PF = computed(() => leaves.sum((l) => l.PF))
  const forecast = computed(() => leaves.sum((l) => l.forecast))
  const velocity = computed(() =>
    PF.value === 0 ? 0 : forecast.value / PF.value,
  )
  const mandatoryLoad = computed(() => leaves.sum((l) => l.mandatoryLoad))
  const nthLoad = computed(() => leaves.sum((l) => l.nthLoad))
  const percLoadOnForecast = computed(
    () => (mandatoryLoad.value * 100) / forecast.value,
  )
  const loadStatus = computed(() => getLoadStatus(percLoadOnForecast.value))

  const totLoad = computed(() => mandatoryLoad.value + nthLoad.value)
  const nthLoadPerc = computed(() => (nthLoad.value * 100) / totLoad.value)
  return computedObj({
    off,
    PF,
    forecast,
    velocity,
    mandatoryLoad,
    nthLoad,
    totLoad,
    nthLoadPerc,
    percLoadOnForecast,
    loadStatus,
  })
}

const domains = ['be', 'fe', 'noDomain'] as const

export const buildStatsTree = (sprint: Sprint) => {
  // compute leaves
  // TODO:
  // remove stats3, class and other dependencies
  // refactor issue.stats and other similar cases
  // then clean objects.ts

  const issuesWithCandidates = computed(() => [
    ...(sprint.issues?.map((i) => ({ issue: i, nth: i.isNth })) ?? []),
    ...(sprint.issueCandidates ?? []),
  ])

  const leaves = createObj(['private', 'sitter'], (teamName) => {
    // shared computeds for each team (optimization)
    const mandatoryIssues = computed(() =>
      issuesWithCandidates.value
        .filter((i) => !i.nth && i.issue.teamName === teamName)
        .map((i) => i.issue),
    )
    const nthIssues = computed(() =>
      issuesWithCandidates.value
        .filter((i) => i.nth && i.issue.teamName === teamName)
        .map((i) => i.issue),
    )
    return createObj(domains, (domain) =>
      buildLeaf(sprint, teamName, domain, mandatoryIssues, nthIssues),
    )
  })
  // for each team, compute aggregates
  const tree = mapValues(
    (teamStats) => ({
      ...teamStats,
      all: buildAggregate(Object.values(teamStats)),
    }),
    leaves,
  )

  // access all teams' leaves and compute vertical aggregates (by domain)
  const all = createObj([...domains, 'all'], (domain) =>
    buildAggregate(Object.values(tree).map((x) => x[domain])),
  )

  return { ...tree, all }
}
