import dayjs, { Dayjs } from 'dayjs'
import { Issue, type WithSprint } from '~/api/issues/models'
import { getDayBoundaries } from '~/components/burndown/utils'
import { getDevDomain, getUserByGlId } from '~/services/cfg/cfg'
import { DevStats } from './stats/DevStats'
import { getPf } from '~/services/cfg/perf'
import { bugsHub } from '~/api/issues/models/BugsHub'
import { AggregateStats } from './stats/AggregateStats'
import { getReleaseStats } from './stats/releaseStats'
import { buildStatsTree } from './stats/loadStats'
import { sprintsHub } from './SprintsHub'
import { glGetAll } from '~/api/common'
import { CFG } from '~/services/cfg'
import { UNPLANNED_START_COUNT } from '..'
import {
  getSprintResults,
  SprintResults,
} from '~/api/issues/models/specialIssues/SprintResults'
import { getSprintsCfg } from '~/api/issues/models/specialIssues/SprintCfg'

const generalProjectId = CFG.projects.general.id

export const getMilestoneIssues = async (milestoneId: number) =>
  glGetAll<GLIssue[]>(
    generalProjectId.toString(),
    `milestones/${milestoneId}/issues`,
  )

// TODO: refactor this in order to have an individual results computed and a resultPayload computed
// from results: results should be used to show full information about individual progress,
// while resultPayload just for saving the payload: think a bit about this and where we can use
// it for live results of current sprint.
const getResultBuilder = (issues: Issue[]) => (devId: number) => {
  const user = getUserByGlId(devId)!
  const userRelatedGenIssues = issues.filter(
    (i) =>
      !!i.techIssues?.some((ti) => ti.assignee?.username === user.glUsername),
  )
  const inaccurateIssues = userRelatedGenIssues
    .filter((i) => i.inaccuracy > 0)
    .map((i) => i.iid)

  const releasedTech = userRelatedGenIssues
    .filter((i) => i.isReleased)
    .flatMap(
      (i) =>
        i.techIssues?.filter(
          (ti) => ti.assignee?.username === user.glUsername,
        ) ?? [],
    )
  const unreleasedTech = userRelatedGenIssues
    .filter((i) => !i.isReleased)
    .flatMap(
      (i) =>
        i.techIssues?.filter(
          (ti) => ti.assignee?.username === user.glUsername,
        ) ?? [],
    )

  const budget = releasedTech
    .filter((i) => i.isBudget)
    .sum((i) => i.deliveredWeight)
  const deliveredSpent = releasedTech.sum((i) => i.sprintSpentSP)
  const delivered = releasedTech.sum((i) => i.deliveredWeight)
  const undeliveredSpent = unreleasedTech.sum((i) => i.sprintSpentSP)
  const totalSpent = deliveredSpent + undeliveredSpent
  const fastTrackDelivered = releasedTech.sum((i) =>
    i.isFastTrack ? i.sprintStartWeight : 0,
  )

  const devDomain = getDevDomain(devId)
  const devRAndDSpent = [...releasedTech, ...unreleasedTech].sum((i) =>
    i.domain && i.domain !== devDomain ? i.sprintSpentSP : 0,
  )

  return {
    delivered,
    deliveredSpent,
    totalSpent,
    budget,
    inaccurateIssues,
    fastTrackDelivered,
    devRAndDSpent,
  }
}

@store()
export class Sprint {
  @lazy(async (s) =>
    (await getMilestoneIssues(s.id)).map(
      (m) => new Issue(m, s) as WithSprint<Issue>,
    ),
  )
  protected _issues?: WithSprint<Issue>[]

  constructor(
    private fromApi: GLMilestone,
    private sprintsCfg: Awaited<ReturnType<typeof getSprintsCfg>>,
    public isUnallocated = false,
  ) {}

  get techIssues() {
    return this.issues?.flatMap((i) => i.techIssues ?? [])
  }

  get state() {
    return this.fromApi.state
  }

  get id() {
    return this.fromApi.id
  }

  get iid() {
    return this.fromApi.iid
  }

  get title() {
    return this.fromApi.title
  }

  private get cfg() {
    return this.sprintsCfg.cfgs[this.number]! // no sprint is created without cfg
  }

  get teams() {
    return this.cfg.teams
  }
  get sprintDates() {
    return this.cfg.sprintDates.sort((a, b) => a.diff(b))
  }
  get isFuture() {
    return !this.startDate || this.startDate?.isFuture()
  }

  get startDate() {
    return this.sprintDates.first()
  }

  get endDate() {
    return this.sprintDates.last()
  }

  /**
   * Sprint length in days
   */
  get length() {
    return this.sprintDates.length
  }

  async removeIssue(issue: Issue) {
    const issues = await lload(() => this._issues)
    issues.removeByIid(issue.iid)
  }

  async addIssue(issue: WithSprint<Issue>) {
    const issues = await lload(() => this._issues)
    issues.push(issue)
  }

  // override to return correct type
  /**
   * Returns all OPEN issues in the sprint
   */
  get issues() {
    return this._issues?.filter((i) => !i.isClosed)
  }

  /**
   * Returns all CLOSED issues in the sprint
   */
  get closedIssues() {
    return this._issues?.filter((i) => i.isClosed)
  }

  saveSprintCfg(teams: TeamsCfg, sprintDates: Dayjs[]) {
    return this.sprintsCfg.saveSprintCfg(this.number, teams, sprintDates)
  }

  get hasResults() {
    if (this.resultsSnapshot !== undefined)
      return (
        this.resultsSnapshot !== null &&
        !!Object.keys(this.resultsSnapshot.individual).length
      )
  }

  // #region results management
  get currentResults(): GLSprintResults {
    const devIds = Object.values(this.teams).flatMap((team) =>
      team.FErs.concat(team.BErs).map((dev) => dev.glId),
    )

    const issues = this.issues ?? []

    const withInaccuracy = issues
      .filter((i) => i.inaccuracy > 0)
      .reduce(
        (acc, curr) => ({ ...acc, [curr.iid]: curr.inaccuracy }),
        {} as Record<number, number>,
      )

    const getResult = getResultBuilder(issues)
    return {
      individual: devIds.reduce(
        (acc, id) => ({ ...acc, [id]: getResult(id) }),
        {} as Record<number, GLSprintResult>,
      ),
      issues: withInaccuracy,
    }
  }

  @lazy(getSprintResults) private readonly sprintsResults?: SprintResults

  get resultsSnapshot() {
    if (this.sprintsResults)
      return this.sprintsResults.data[this.number] ?? null
  }

  /**
   * returns snapshot results if available, otherwise current results (still open sprint use case)
   */
  get currentOrSnapshotResults(): GLSprintResults {
    return this.hasResults ? this.resultsSnapshot! : this.currentResults
  }

  get areResultsUpToDate() {
    return (
      !!this.resultsSnapshot &&
      deepEqual(this.currentResults, this.resultsSnapshot)
    )
  }
  saveResults() {
    return getSprintResults().then((sr) =>
      sr.save(this.number, this.currentResults),
    )
  }

  /**
   * returns bugs reported by other people than devs in the sprint
   */
  get bugsReportedByPos() {
    const { startDate, endDate } = this
    if (!startDate || !endDate) return []
    return (
      bugsHub.bugs?.filter(
        (b) =>
          b.createdAt.isBetween(startDate, endDate) &&
          !this.devIds.includes(b.author.id),
      ) ?? []
    )
  }

  get devs() {
    return Object.values(this.teams).flatMap((t) => t.FErs.concat(t.BErs))
  }

  get devIds() {
    return this.devs.map((dev) => dev.glId)
  }

  readonly dStats = computedObj((id) => {
    const glId = Number(id)
    const dev = this.devs.find((d) => d.glId === glId)
    if (dev) return new DevStats(this, dev)
  })

  get devStats(): DevStats[] {
    return this.devs.map((dev) => new DevStats(this, dev))
  }

  get inaccurateIssuesWeight() {
    return this.currentOrSnapshotResults.issues
  }

  readonly sprintStats = new AggregateStats(this)

  // #endregion results management

  get perfRatio() {
    if (!this.startDate) return undefined
    return getPf(this.startDate)
  }

  // TODO: temporary - just a corrective action for Q3 devPlans
  get sdDeltaInpact() {
    // compute the impact of SD on the sprint considering Marco Ottone estimation:
    // from sprint 13 on, we triled time spent on solution design from 1 to 3 SP / dev / sprint,
    // so we are roughly and on average investing 1 day more than before in SD.
    // Therefore we want to compute the percentage of SD time delta introduced in each sprint and remove that
    // percentage from every dev PF as a corrective action to have a fair comparison with previous sprints.
    return this.number < 13 ? 0 : 1 / this.length
  }

  get number() {
    return Number(this.title)
  }

  /**
   * unique sprint name for display purposes (or as unique ID)
   */
  get name() {
    return this.isUnallocated
      ? `remote #${this.number - UNPLANNED_START_COUNT + 1}`
      : `sprint #${this.number}`
  }

  /**
   * Sprint label for planning purposes.
   * This is not unique since it changes over time for each sprint.
   */
  get planningLabel() {
    return this.isUnallocated
      ? this.name
      : `sprint +${this.number - (sprintsHub.currentSprint?.number ?? 0)}`
  }

  get sprintDaysTillNow() {
    return this.sprintDates.filter((d) => !d.isFuture('day'))
  }

  get missingSprintDays() {
    return this.sprintDates.filter((d) => !d.isPast('day'))
  }

  get dayBoundaries() {
    return this.sprintDates.map((_d, i) =>
      getDayBoundaries(this.sprintDates, i),
    )
  }

  releasedStats = getReleaseStats(this)

  dayBelongsToSprint(day: Dayjs = dayjs()) {
    const { startDate, endDate } = this
    if (!startDate || !endDate) return false
    return day.isBetween(startDate, endDate, 'day')
  }

  /**
   * sets all issues and tech issues' workflow state to 'TODO'
   */
  async kickoffSprint() {
    const issues = await lload(() => this.issues)
    await Promise.all(issues.map((i) => i.setAllAsTodo()))
  }

  stats = buildStatsTree(this)
  // stats = buildStats(this)

  get issueCandidates() {
    return sprintsHub.allIssues
      ?.filter((i) => i.candidate?.sprint?.number === this.number)
      .map((i) => ({ issue: i, nth: i.candidate!.nth }))
  }
}
