import { CFG } from '~/services/cfg'
import { getIssue, updateDescription } from '~/api/issues'

// https://gitlab.com/kampaay/general/-/issues/3075
export const SPRINTS_CFG_ISSUE_ID = 3075

// https://gitlab.com/kampaay/general/-/issues/2734
export const SPRINTS_RESULTS_ISSUE_ID = 2734

// https://gitlab.com/kampaay/general/-/issues/3054
export const PLANNING_ISSUE_ORDER = 3054

// https://gitlab.com/kampaay/general/-/issues/3095
export const SEEDS_ISSUE_ORDER = 3095

// https://gitlab.com/kampaay/general/-/issues/3234
export const CANDIDATE_SPRINT_ISSUES = 3234

const generalProjectId = CFG.projects.general.id

@store()
export class SpecialIssue<TEntity extends object> {
  constructor(private fromApi: GLIssue) {}
  protected _parse(serialized: string) {
    return JSON.parse(serialized) as TEntity
  }
  protected _write(data: TEntity) {
    return JSON.stringify(data)
  }
  protected get data() {
    return this._parse(this.fromApi.description!)
  }
  /**
   * returns updated data after applying the updaterOrValue (if function), otherwise the value itself
   * in case of function, the current data could be passed as an argument
   * in that case it will not force the refetch of the data
   * @param updaterOrValue
   * @param current
   * @returns
   */
  private async getDataToBeSaved(
    updaterOrValue: TEntity | ((data: TEntity) => TEntity),
    current?: TEntity,
  ) {
    if (typeof updaterOrValue === 'function') {
      return updaterOrValue(
        (current ??= this._parse(
          (await getIssue(generalProjectId, this.fromApi.iid)).description!,
        )),
      )
    }
    return updaterOrValue
  }
  protected async _save(
    updaterOrValue: TEntity | ((data: TEntity) => TEntity),
  ) {
    // reducing concurrency problems: we avoid overriding the whole payload with data we fetched time ago.
    // We fetch the most recent version and update only the specific part

    // optimistic update (no force fetch)
    this.fromApi.description = this._write(
      await this.getDataToBeSaved(updaterOrValue, this.data),
    )

    // real update: forces re-fetch
    const data = await this.getDataToBeSaved(updaterOrValue)

    this.fromApi.description = (
      await updateDescription(
        generalProjectId,
        this.fromApi.iid,
        this._write(data),
      )
    ).description!
    return this.data
  }
}

/**
 * A simple container that stores the data in the description of a special issue.
 * The data is parsed and stringified as JSON.
 * The shape of the data can be specified by the generic type.
 * Fallback for `buildIssueStoreLoader` when no class is provided.
 */
class SimpleContainer<T extends object> extends SpecialIssue<T> {
  save(data: T) {
    return super._save(data)
  }
  override get data() {
    return super.data
  }
}

/**
 * Builds a singleton store loader based on data encoded in a special issue description.
 * The resolved class instance will be created with the data from the issue as soon as it is loaded.
 * If no class is provided, a SimpleContainer will be used. In this case,
 * do provide the generic type in order to specify the shape of the data.
 *
 * @param issueIid
 * @param clazz the class to be instantiated with the data from the issue
 * @returns
 */
export const buildIssueStoreLoader = <
  TEntity extends object,
  TClass extends new (
    fromApi: GLIssue,
  ) => InstanceType<TClass> = typeof SimpleContainer<TEntity>,
>(
  issueIid: number,
  clazz?: TClass,
): (() => Promise<
  TClass extends undefined ? SimpleContainer<TEntity> : InstanceType<TClass>
>) =>
  singletonFactory(
    () =>
      getIssue(generalProjectId, issueIid).then((issue) =>
        clazz ? new clazz(issue) : new SimpleContainer<TEntity>(issue),
      ) as any,
  )
