import { glGetAll } from '~/api/common'
import { CFG, LABELS } from '~/services/cfg'
import {
  createMilestone,
  milestoneIsValidSprint,
  milestoneIsUnplanned,
} from '..'
import { Sprint } from './Sprint'
import { issuesHub } from '~/api/issues/models/IssueHub'
import { toast } from 'vuetify-sonner'
import { createIssue } from '~/api/issues'
import {
  Issue,
  summaryToDescription,
  type WithSprint,
} from '~/api/issues/models'
import { getDevVelocityRecord } from './stats/velocity'
import { getSprintCandidates } from '~/api/issues/models/specialIssues/SprintCandidates'
import {
  getSprintsCfg,
  SprintsCfg,
} from '~/api/issues/models/specialIssues/SprintCfg'
import {
  getPlanningOrder,
  type OrderManager,
} from '~/api/issues/models/specialIssues/PlanningOrder'

const loadMilestones = singletonFactory(() =>
  glGetAll<GLMilestone[]>(CFG.projects.general.id.toString(), `milestones`),
)

/**
 * root node:
 * handles planning and sprints
 */
@store()
class SprintsHub {
  // dependency of other loaders so do not sync lazy loading
  @lazy(getSprintsCfg, false) private readonly _sprintsCfg?: SprintsCfg

  private async getSprints(unallocated = false) {
    const [milestones, sprintsCfg] = await Promise.all([
      loadMilestones(),
      lload(() => this._sprintsCfg),
    ])
    return milestones
      .filter(unallocated ? milestoneIsUnplanned : milestoneIsValidSprint)
      .map((m) => new Sprint(m, sprintsCfg, unallocated))
      .sort((a, b) => a.number - b.number)
  }

  @lazy((sh) => sh.getSprints()) readonly sprints?: Sprint[]

  @lazy((sh) => sh.getSprints(true)) readonly unplanned?: Sprint[]

  async createSprint(teams: TeamsCfg, sprintDates: DayJs[]) {
    const sprints = await lload(() => this.sprints)
    if (sprintDates.length < 1) {
      toast.error('Sprint must have at least 1 day')
      return
    }
    sprintDates = sprintDates.sort((a, b) => a.diff(b))

    const startDate = sprintDates[0]!
    const endDate = sprintDates[sprintDates.length - 1]!

    const [milestone, sprintsCfg] = await Promise.all([
      createMilestone({
        title: this.nextSprintNumber!.toString(),
        project_id: CFG.projects.general.id.toString(),
        start_date: startDate.toISOString().substring(0, 10),
        due_date: endDate!.toISOString().substring(0, 10),
      }),
      lload(() => this._sprintsCfg).then(async (sc) => {
        await sc.saveSprintCfg(this.nextSprintNumber!, teams, sprintDates)
        return sc
      }),
    ])
    const newSprint = new Sprint(milestone, sprintsCfg)
    sprints.push(newSprint)
  }

  get sprintsAndUnplanned() {
    const { sprints, unplanned } = this
    if (!sprints || !unplanned) return undefined
    return sprints.concat(unplanned)
  }

  /**
   * sprints with results
   */
  get closedSprints() {
    return this.sprints?.filter((s) => s.hasResults === true)
  }

  /**
   * sprints without results
   */
  get openSprints() {
    return this.sprints?.filter((s) => s.hasResults === false)
  }

  get currentSprint() {
    return this.openSprints?.first()
  }

  // #region planning

  @lazy(getPlanningOrder) private readonly orderManager?: OrderManager

  get planningSprints() {
    if (!!this.openSprints && !!this.unplanned) {
      return [...this.openSprints, ...this.unplanned]
    }
  }

  get planningIssues() {
    const planningSprints = this.planningSprints

    if (!!planningSprints && planningSprints.every((s) => !!s.issues)) {
      return this.orderManager?.getOrderedItems(
        planningSprints.flatMap((s) => s.issues!),
      )
    }
  }

  async movePlanningIssue(moveFrom: Issue, moveTo: Issue) {
    const [orderMgr, issues] = await lload(
      () => this.orderManager,
      () => this.planningIssues,
    )
    return orderMgr.move(issues, moveFrom, moveTo)
  }
  // #endregion

  get nextSprintNumber() {
    return this.sprints!.last()!.number + 1
  }

  get sprintsCount() {
    return this.sprints?.length
  }

  /**
   * all issues in past sprints (closed sprints with results)
   * INCLUDING closed issues
   */
  get pastIssues() {
    return this.closedSprints?.flatMap((s) => [
      ...(s.issues ?? []),
      ...(s.closedIssues ?? []),
    ])
  }

  /**
   * all issues in the current sprint
   */
  get currentSprintIssues() {
    return this.currentSprint?.issues
  }

  /**
   * all issues in future sprints (open sprints and unplanned excluding the current one)
   */
  get futureIssues() {
    if (!this.planningSprints || !this.currentSprint) return
    return this.planningSprints
      .removeI(this.currentSprint)
      .flatMap((s) => s.issues ?? [])
  }

  /**
   * all issues in all sprints
   */
  get allIssues() {
    if (!!this.pastIssues && !!this.futureIssues && !!this.currentSprintIssues)
      return [
        ...this.pastIssues,
        ...this.futureIssues,
        ...this.currentSprintIssues,
      ]
  }

  /**
   * when true, velocity and forecast will consider open sprints (current one) in stats
   * for both sprintsHub and specific sprint
   */
  public includeCurrentSprintInForecast = false

  /**
   * devs velocity considering the includeCurrentSprintInForecast flag
   */
  devVelocity = getDevVelocityRecord()

  /**
   * Assigns an issue to a different sprint and takes care of updating "sprint.issues" collections
   * or unassigns an issue from a sprint and takes care of updating "sprint.issues" and "unassigned" collections.
   * In case of unassignment, if the issue is a candidate for a future sprint, it will not be unassigned, but
   * reassigned to the future sprint.
   * Possible candidacy is removed in any case.
   * @param sprintIssue
   * @param srcSprintNumber
   * @param targetSprintNumber
   */
  async assignSprint(sprintIssue: Issue, targetSprint?: Sprint) {
    const srcSprint = sprintIssue.sprint

    // if a candidacy is present, we do not unassign, but reassign to the future sprint
    targetSprint = targetSprint ?? sprintIssue.candidate?.sprint

    if (!targetSprint) {
      // unassignment case: unassign from sprint and add to unassigned issues
      if (!srcSprint) return
      await sprintIssue.assignSprint()
      srcSprint.removeIssue(sprintIssue)
      issuesHub.addUnassigned(sprintIssue)
    } else {
      // reassignment case: if issue is already assigned to a sprint, assign it to the new sprint,
      // remove it from the old sprint and add it to the new sprint collection
      const withSprint = await sprintIssue.assignSprint(targetSprint)
      targetSprint.addIssue(withSprint)
      if (srcSprint) srcSprint.removeIssue(sprintIssue)
      else issuesHub.removeUnassigned(sprintIssue)
    }

    // always remove candidacy if any
    await getSprintCandidates().then((sc) => sc.setAssignation(sprintIssue.iid))
  }

  async addNewIssueToUnallocated(
    title: string,
    summary: string,
    isRAndD = false,
    teamName?: TeamName,
  ) {
    const sprint = await lload(() => this.unplanned?.last())
    const issues = await lload(() => sprint.issues)
    const labels = teamName ? [CFG.teams[teamName].glLabel] : []
    if (isRAndD) labels.push(LABELS.RAndD)

    const issue = await createIssue(CFG.projects.general.id, title, {
      milestoneId: sprint.id,
      description: summaryToDescription(summary),
      labels,
    })
    const sprintIssue = new Issue(issue, sprint) as WithSprint<Issue>
    issues.push(sprintIssue)
    return sprintIssue
  }
}

export const sprintsHub = new SprintsHub()
;(window as any).shw = sprintsHub
