import {
  buildIssueStoreLoader,
  SpecialIssue,
  SPRINTS_RESULTS_ISSUE_ID,
} from './SpecialIssue'

type GLSprintsResults = Record<number, GLSprintResults>

@store()
export class SprintResults extends SpecialIssue<GLSprintsResults> {
  save(sprintNumber: number, results: GLSprintResults) {
    return this._save((data) => {
      data[sprintNumber] = results
      return data
    })
  }
  override get data() {
    return super.data
  }
}

export const getSprintResults = buildIssueStoreLoader(
  SPRINTS_RESULTS_ISSUE_ID,
  SprintResults,
)
