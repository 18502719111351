import {
  getSeedsOrder,
  type OrderManager,
} from '~/api/issues/models/specialIssues/SeedsOrder'

import { getSeeds } from '..'
import { Seed } from './Seed'

@store()
class SeedsHub {
  @lazy(() => getSeeds().then((seeds) => seeds.map((s) => new Seed(s))))
  private _seeds?: Seed[]
  @lazy(getSeedsOrder) private readonly orderManager?: OrderManager
  get seeds() {
    if (!!this._seeds) return this.orderManager?.getOrderedItems(this._seeds)
  }

  get openIssues() {
    return this.seeds?.flatMap((seed) => seed.openIssues).distinctByIid() ?? []
  }

  /**
   * the sum of the effort remaining (SP) for all the issues related to open seeds
   */
  get effortRemaining() {
    return this.openIssues.sum((i) => i.weight)
  }

  get rAndDOpenIssues() {
    return this.openIssues.filter((issue) => issue.isRAndD)
  }

  /**
   * the sum of the R&D effort remaining (SP) for all the issues related to open seeds
   */
  get rAndDEffortRemaining() {
    return this.rAndDOpenIssues.sum((i) => i.weight)
  }

  async addSeed(seed: Seed) {
    const seeds = await lload(() => this._seeds)
    seeds.unshift(seed)
  }

  /**
   * seed removal: CLOSE the issue first to be not visible when refetching!
   * @param seed
   * @returns
   */
  async deleteSeed(seed: Seed) {
    const seeds = await lload(() => this.seeds)
    const index = seeds.indexOf(seed)
    if (index !== -1) seeds.splice(index, 1)
  }
  async moveSeed(moveFrom: Seed, moveTo: Seed) {
    const [orderMgr, seeds] = await lload(
      () => this.orderManager,
      () => this.seeds,
    )
    return orderMgr.move(seeds, moveFrom, moveTo)
  }
}

export const seedsHub = new SeedsHub()
