import type { ClassDecCtx, FieldDecoratorContext } from './decoratorTypes'

type Class = abstract new (...args: any) => any

/**
 * prop key of a class constructor to store the semaphore marker
 */
const SEM_MARKER_KEY = Symbol('semaphore')
type SemMarker = Symbol | false
const getSemMarker = (obj: object): SemMarker | undefined =>
  Object.getPrototypeOf(obj)?.constructor[SEM_MARKER_KEY]
const setSemMarker = (obj: object, sym: SemMarker) => {
  Object.getPrototypeOf(obj).constructor[SEM_MARKER_KEY] = sym
}

/**
 * retrieves the semaphore symbol from the class constructor
 * or its inherited classes constructors.
 * @param obj
 */
const retrieveSemMarker = (obj: object): SemMarker | undefined =>
  obj === null
    ? undefined
    : (getSemMarker(obj) ?? retrieveSemMarker(Object.getPrototypeOf(obj)))

/**
 * retrieves the semaphore symbol from the class constructor
 * or its inherited classes constructors.
 * When no semaphore is found in the inheritance chain, a new one is generated
 * and stored in the class constructor (following retrievals on instances of
 * the same class will return the same one).
 * @param obj
 * @returns
 */
const safeRetrieveSemMarker = (obj: object): SemMarker => {
  const sem = retrieveSemMarker(obj)
  if (sem === undefined) {
    const sym = Symbol('default semaphore')
    setSemMarker(obj, sym)
    return sym
  }
  return sem
}

const semMap = new Map<Symbol, Semaphore>()
const getSemaphore = (sym: Symbol) => {
  if (!semMap.has(sym)) {
    semMap.set(sym, semaphoreBuilder())
  }
  return semMap.get(sym)!
}

const defineAccessors = (
  obj: object,
  propName: string,
  get: () => any,
  restDesc: {
    set?: (v: any) => void
    configurable?: boolean
    enumerable?: boolean
  },
) => {
  const { set, configurable = true, enumerable = true } = restDesc
  Object.defineProperty(obj, propName, {
    get,
    set,
    configurable,
    enumerable,
  })
}

const setOnInstance = (
  obj: object,
  mapId: symbol,
  clazz: Class,
  value: ComputedRef<any>,
) => {
  const casted = obj as {
    [key: typeof mapId]: Map<Class, ComputedRef<any>>
  }
  casted[mapId] ??= new Map<Class, ComputedRef<any>>()
  casted[mapId].set(clazz, value)
}

const getFromInstance = (obj: object, mapId: symbol, clazz: Class) => {
  const casted = obj as {
    [key: typeof mapId]: Map<Class, ComputedRef<any>>
  }
  return casted[mapId]?.get(clazz)
}

const getDescEntries = (obj: object) =>
  Object.entries(Object.getOwnPropertyDescriptors(obj))

const getOwnedValue = <T>(obj: object, propName: string | symbol) =>
  Object.getOwnPropertyDescriptor(obj, propName)?.value as T | undefined

// test sandbox: https://play.vuejs.org/#eNrNWN1T4zYQ/1e2ns7E4XKG0D4xhCsHPLQPBwO8EaZnbDkxOJJHkvmYTP737urDlnNJ5nptmfIxsaXd337vSllGp3WdPDcsOoqOVSbLWoNiuqlPprxc1EJqWIJkxQgysagbzXJYQSHFAgbINJjyKc8EVxpmTJ8zCyCkggnEUw4gHh5HUz6EyQks6X1/H5QWNbzMGYeSz5ksdcozBtk8LTkKSrM5U3D58MgyTQwSdZGccOCnycRtJLUUWui3mhEJwCcLbn+SJHFUqNLlC7+SomZSvwXaxQg3RL0Clr76cYeA7FbUZWHYOr6VfTiCJT7h35onjA+Ig4wnUofJuJYlU/GaRINNZElRVprJOL5DK+sv6YKNIEfC+86LAGUB8d3AyJNNhgCDEQz0vFSD+6TkWdUgR+z5h0PvxiKtFPrMYlAsnsq6LvkMFkzPRa4++T1Hj+DGxSvUzG5ZE9GC2YyZMGNuxHf3Zh93q1QpuGVI4hTNRMM1krUBKtgRHDgfOg+iwhh4xeJhS0WmJIY3KdiHD36ZpIuKJZWYxYO9vb2WFc3vsdhX489QDIUkdOPfEvQ9MjCoaOG5aB4qNCcQ4xyWPKdVw5K6UfN44AkHDqNzeygG9uCwJwPjhjbngldvkBsAu26fycQ1G23MEGnSFvE6yRYNLWSn33YNnW4A67YEIqxzQrcEdAWzkrutFtI9rIaxA6c4NjxnRclZ3jk+u6oaNQ6zaJNNlmqLz2GCTaaFxsZygPXttj7AuBcHbJNe5PN65qKCz/Cxx2A6BMbuFpsegxT/9YsA6iwKRGFK3DdPLCKRlSk125dSzyHlb0DFTG3sCPJUp31ynkOaZQy5ZLjR1WupzpGJ2g2lx92fmzrLwDhpgJluFl239vynDn83Bj1ZZ5tu3YtRp02dSl3qUnACImtGaB3LywwtDtPSUi8PjmA2HsEYPw5XyOJ7sxRN/fnNAeTE16LE+dDFbuzi7KJ8R0izw/s2IF6KDLt1Tz7ZRI3Ot3VD5fukJbkjHWhz1MaB3u6Rq7U1NjijIBQ9EN9slpuhqAETadsBSv6xrlIcnFqmXBVCLsD4XVH8sCcrWOBA1fhP7V2VM56iA3yncDIS5LvAibt71qyr+HWeZk+Een56ewo/Lz3r6mvQJyosjlc3HrqsCAhcFG2C+BlNvqVk8Lr0GgUG4Mi171cLF4xwU464/Ww19xSmDlc9OjSlKGeNTLGzYTGRbuFSj5bxZsFCym4hoPPBsVNye4DQABzsJkK+D+8MU5gA/zBUp2dnFzc3l9fbwuVKDTWkCvP1H+6RxhsGiMvIDVPz9vr0CkemcRviBsLaasTlJEurypXUuj9/OE3WR0wrkLRfHzMo79s8Mlrj0ztmTlDee65MqWN8b+BD3/cJW2/SodFvmUGHB0ic4INueG3Jnnet8a3BcyW9M3LhJLY/QSPocW5Kt/ag8e/W8X8fx+4kuWwt/LGY/m8bQQ+yqXGEoRWbYf1Se9rfVPbv3Vg2Zhl97u0H2eavu9esCOG6W/A3BxeyUsacvZhrV0x3SPo93rfHQLzG44tmC0xizfAN4Hg+ht+yqsyeJtMI/d5dfqKT7hWvtUvoXmG1Ot6fjx3AYQjQXd8MgL0zdAjtu4U43ALhuH0mO2b/uoM384p7jXfRmuM6emx8YHnMu2e0myE3HihP/CXCUvmLL1HRLnk68G40irSyQyF5VILjtyomYaYRZUhZMXlZ02lQTSPEc00/wswXL3+YNbpxu6xBnjnLnjasP6pXWptGV5IpJp/ZNGr3dCoxUe32xc0X9orP7eZC5A1eF3dtXjMss4Z0tGSf8RCPagd0RtvfzXdDWIK36uJVM668Ue1XBoZ+GuG3RGc7TO/U/SX51SX3Klr9BZykycI=
/**
 * class-level decorator to transform all fields of a class to reactive ones
 * and all getters to computed properties without modifying the class interface.
 *
 * When using class inheritance, we can partially/selectively apply the decorator
 * to the classes in the inheritance chain: when a superclass `Super` is not tagged
 * with `@store`, the getters of `Super` are not transformed into computed.
 *
 * For a complete reference on how to use it, see v3 tests.
 *
 * @param semaphoreSym a symbol used to synchronize the lazy loading of instances
 * @returns
 */
export const store = (semaphoreSym?: SemMarker) =>
  function <T extends Class>(wrapped: T, { kind, name }: ClassDecCtx) {
    // value is the actual class this decorator is applied to
    if (kind === 'class') {
      // this will wrap the original class
      abstract class StoreWrapper extends wrapped {
        constructor(...args: any[]) {
          super(...args)
          // when @store is used multiple times in the inheritance chain, following code is executed
          // for each tagged class from the base class to the most derived one

          // lazy semaphore marker: if provided, a marker is stored in the class constructor
          if (semaphoreSym !== undefined) setSemMarker(this, semaphoreSym)

          // gets all descriptor of the instance itself where all the fields are defined
          // and transforms data fields into reactive accessors
          const fieldsDescs = getDescEntries(this)

          // There are two types of descriptors associated with any property: data descriptors and accessor descriptors
          // https://web.dev/learn/javascript/objects/property-descriptors/

          fieldsDescs.forEach(([propName, desc]) => {
            // data descriptors
            if ('value' in desc) {
              // console.log(wrapped, `hacking DATA ${propName}`)

              let r = ref(desc.value)
              defineAccessors(this, propName, () => r.value, {
                ...desc,
                set: (v: any) => (r.value = v),
              })
            }
          })

          const classProto = wrapped.prototype

          // gets all descriptor of the class
          const accessorDescs = getDescEntries(classProto)

          accessorDescs.forEach(([propName, desc]) => {
            const { get } = desc
            if (propName !== 'constructor' && !!get) {
              // just descriptors with a getter are hacked
              // console.log(wrapped, `hacking ACCESSOR ${propName}`)

              const computedSym = Symbol.for(`computedMap_${propName}`)
              const getterSym = Symbol.for(propName)

              // we retrieve the getter from the class prototype (if it was already hacked)
              let cachedGetter = getOwnedValue<() => any>(classProto, getterSym)

              // skip if found: prototype should be hacked just once, not for each instance.
              if (!cachedGetter) {
                classProto[getterSym] = get
                defineAccessors(
                  classProto,
                  propName,
                  function (this: any) {
                    // this SHOULD be a function (not an arrow) since this is bound to the instance
                    return getFromInstance(this, computedSym, wrapped)!.value
                  },
                  desc,
                )
              }

              cachedGetter ??= get

              setOnInstance(
                this,
                computedSym,
                wrapped,
                computed(() => cachedGetter.call(this)),
              )
            }
          })
        }
      }
      return StoreWrapper
    }
  }

/**
 * Field-level decorator that lazy loads a field value.
 * When accessed for the first time, returned value is undefined and the field value is
 * loaded asynchronously.
 *
 * Under the hood, the real value is always wrapped in a ref, so the field is always reactive.
 *
 * @param lazyLoader a function that returns a promise of the field value
 * @param semaphoreSym a symbol used to synchronize the lazy loading of instances or false (do not sync)
 * @returns
 */
export const lazy =
  <T, TInstance extends object, TLoader extends (obj: TInstance) => Promise<T>>(
    lazyLoader: TLoader,
    semaphoreSym?: SemMarker,
  ) =>
  (
    _value: undefined,
    { kind, name, addInitializer }: FieldDecoratorContext<T, TInstance>,
  ): void => {
    if (kind !== 'field')
      throw new Error('store decorator should be used on fields only!')

    addInitializer(function (this: TInstance) {
      // @ts-ignore
      let currentValue = ref(this[name]) as Ref<T>
      let loading = false

      const getLoader = singletonFactory((obj: TInstance) => {
        if (semaphoreSym === false) return lazyLoader
        const sem = semaphoreSym ?? safeRetrieveSemMarker(obj)
        if (sem === false) return lazyLoader
        return getSemaphore(sem)(lazyLoader)
      })
      Object.defineProperty(this, name, {
        get: function (): T {
          const val = currentValue.value
          // triggering the load only if the value is undefined and not already loading
          if (val === undefined && !loading) {
            // differing the loading avoids any access to reactive values
            // that would result in tracking them as dependencies for computed
            // relying on the this accessed reactive value!
            // refs:
            // - https://vuejs.org/guide/extras/reactivity-in-depth
            // - https://dev.to/alexanderop/how-to-build-your-own-vue-like-reactivity-system-from-scratch-1d2d
            loading = true
            setTimeout(async () => {
              const load = getLoader(this)
              // const load = lazyLoader
              const v = await load(this)
              currentValue.value = v
              loading = false
            })
          }
          return val
        },
        set: function (newValue: T) {
          currentValue.value = newValue
        },
      })
    })
  }
