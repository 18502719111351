import { Sprint } from '../Sprint'
import { getDevByGlId } from '~/services/cfg'
import { buildGetSeniority, defaultSeniority } from '~/services/cfg/perf'
import { getActivitiesTime } from '~/services/cfg/models/Dev'

export const DEV_TIME_INCIDENCE_Q3 = 0.4929469487887151 // TIME SPENT ON DEVELOPMENT ACTIVITIES IN Q3 (PERCENTAGE OVERALL SPRINTS 13-18)
/**
 * this bonus grossly represents the percentage of analysis time saved
 */
const FAST_TRACK_BONUS = 0.25

@store()
export class DevStats {
  constructor(
    public sprint: Sprint,
    private devCfg: GLSprintDevCfg,
  ) {}

  get domain() {
    return Object.values(this.sprint.teams).find((team) =>
      team.FErs.find((dev) => dev.glId === this.devCfg.glId),
    )
      ? 'fe'
      : 'be'
  }

  get team() {
    return entries(this.sprint.teams).find(([teamName, team]) =>
      team.BErs.concat(team.FErs).find((dev) => dev.glId === this.devCfg.glId),
    )![0]
  }

  get dev() {
    return getDevByGlId(this.devCfg.glId)
  }
  private get devSeniority() {
    if (this.sprint.startDate && this.dev) {
      return buildGetSeniority(this.sprint.startDate)(this.dev)
    }
    return defaultSeniority
  }

  private get sprintActivities() {
    if (!this.dev) return this.sprint.sprintDates.map(() => []) ?? []

    return this.dev.getUserActivities(this.sprint.sprintDates)
  }

  private get nonDevSpent() {
    const aggregate = getActivitiesTime(this.sprintActivities.flat())
    // hours to day
    return mapValues((x) => x / 8, aggregate)
  }

  private get results() {
    const results =
      this.sprint.currentOrSnapshotResults.individual[this.devCfg.glId]
    // TODO: find a better way to handle this
    if (!results) {
      throw new Error(
        `Dev ${this.dev?.name} not found in sprint ${this.sprint.number}`,
      )
    }
    return results
  }

  private get devTracked() {
    return this.results.totalSpent / 2 // SP to day: days spent by the dev in the sprint for dev activities
  }

  private get nonDevTracked() {
    return Object.values(this.nonDevSpent).sum((x) => x)
  }

  private get totalTracked() {
    return this.devTracked + this.nonDevTracked
  }

  private get untracked() {
    return this.sprint.length - this.totalTracked
  }

  get spent() {
    return {
      nonDev: this.nonDevSpent,
      totalTracked: this.totalTracked,
      untracked: this.untracked,
      dev: this.devTracked,
      devDelivered: this.results.deliveredSpent / 2, // SP to day
    }
  }

  /**
   * @returns SP
   */
  get devRAndDSpentMd() {
    return (this.results.devRAndDSpent ?? 0) / 2
  }

  /**
   * @returns SP
   */
  get delivered() {
    return this.results.delivered
  }

  get fastTrackDelivered() {
    return this.results.fastTrackDelivered ?? 0
  }

  get fastTrackDeliveredMd() {
    return this.fastTrackDelivered / 2
  }

  get deliveredMd() {
    return this.delivered / 2
  }

  get deliveredAbs() {
    if (!this.sprint.perfRatio) return 0
    return this.deliveredMd * this.sprint.perfRatio
  }

  /**
   * delivered without budget issues and
   * taking into account fast track bonus
   */
  get deliveredForDevEff() {
    if (!this.sprint.perfRatio) return 0
    const normalizedDeliveredMd =
      this.deliveredMd -
      this.budgetMD +
      this.fastTrackDeliveredMd * FAST_TRACK_BONUS
    return normalizedDeliveredMd * this.sprint.perfRatio
  }

  /**
   * total MD spent on budget issues
   */
  get budgetMD() {
    return (this.results.budget ?? 0) / 2
  }

  get workingDays() {
    return this.sprint.length ? this.sprint.length - this.devCfg.daysOff : 0
  }

  get pf() {
    return this.workingDays * (1 - this.sprint.sdDeltaInpact) // temporary to be removed
  }

  get inaccurateIssues() {
    return this.results.inaccurateIssues
  }

  /**
   *  // ideal output of the dev in the sprint considering his seniority and the time spent in dev activities
   */
  get idealDevOutput() {
    return this.devTracked * this.devSeniority
  }

  get idealDevOutputNoBudget() {
    return (this.devTracked - this.budgetMD) * this.devSeniority
  }

  get idealOutput() {
    return this.workingDays * DEV_TIME_INCIDENCE_Q3 * this.devSeniority
  }

  get nonDevTimeLog() {
    return this.sprintActivities.map((acts, i) => ({
      date: this.sprint.sprintDates[i]!,
      acts,
    }))
  }
}
